import {createRouter, createWebHistory} from 'vue-router';

// 引入组件
import HomePage from './components/HelloWorld.vue';

// 定义路由
const routes = [
    {path: '/', component: HomePage, meta: {title: '首页-星遇互游,让直播更简单'}},
];

// 创建router实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    // 设置页面标题
    document.title = to.meta.title || '默认标题'
    next()
})

export default router;
